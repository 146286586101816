import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar'
import certificat1 from "../../assets/img/telechargement/rehau/certificats/certificat-iso-9001-1.jpg"
import certificat2 from "../../assets/img/telechargement/rehau/certificats/zertifikat-tuev-iso-14001-1.jpg"
import certificat3 from "../../assets/img/telechargement/rehau/certificats/rautherm-s-rautitan-certificat-de-garantie-1.jpg"
 
const RehauCertificatPage = () => (
  <Layout>
    <SEO title="certificats de qualité Bas Surface" />
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3"> 
      <div class="grid grid-cols-1 md:col-span-3 mx-3"> {/** first element */}
        <h1 class="text-2xl font-bold mb-2 md:mt-3">Certificats de Qualité</h1> 
           <p class="mx-0 mt-0 mb-5 border-b-4 border-red-500 h-1 w-1/12"></p>
            <a href="https://www.rehau.com/download/1503830/certificat-iso-9001.pdf" target="top"  class=""><img src={certificat1} alt="certificat REHAU" /></a>
            <a href="https://www.rehau.com/download/1340564/zertifikat-tuev-iso-14001.pdf" target="top" class=""><img src={certificat2} alt="certificat REHAU"  /></a>
            <a href="https://www.rehau.com/download/1655086/rautherm-s---rautitan---certificat-de-garantie.pdf" target="top" class="" ><img src={certificat3} alt="certificat REHAU" /></a>
      </div>
      <div>
        <Sidebar />
      </div>
    </div>
  </Layout>
) 

export default RehauCertificatPage